/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:fed76aa2-94e1-4866-8be2-51a00fb2930f",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_WM7WKg0HJ",
    "aws_user_pools_web_client_id": "7fasco5iusfpk451ohc9jl2v14",
    "oauth": {}
};


export default awsmobile;
